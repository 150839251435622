import service from '@/utils/request';


// 获取余额
export function getPointLeft (data) {
  return service({
    url: '/v1/chat/pointLeft',
    method: 'get',
  })
}
// 获取商品列表
export function getList (data) {
  return service({
    url: '/v1/product/list',
    method: 'get',
    params: data
  })
}


// 获取使用记录
export function getUsage (data) {
  return service({
    url: '/v1/chat/usage',
    method: 'get',
    params: data
  })
}

// 诺诺创建订单接口，PC端使用
export function nuonuoCreate (data) {
  return service({
    url: '/v1/orderinfo/nuonuoCreate',
    method: 'post',
    data: data
  })
}


// 诺诺支付
export function getQrImg (data) {
  return service({
    url: '/v1/pay/nuonuo/getQrImg',
    method: 'get',
    params: data
  })
}

// 诺诺处理已支付订单接口
export function dealNuonuoPayedOrder (data) {
  return service({
    url: '/v1/orderinfo/dealNuonuoPayedOrder',
    method: 'post',
    data: data
  })
}