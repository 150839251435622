import service from '@/utils/request';

// 跳转登录接口
export function jumpLogin (data) {
  return service({
    url: '/v1/auth/jumpLogin',
    method: 'get',
    params: data
  })
}

// 模块列表
export function menuList (data) {
  return service({
    url: '/v1/module/list',
    method: 'get',
    params: data
  })
}

// 获取新chatId
export function getNewChatId (data) {
  return service({
    url: '/v1/chat/getNewChatId',
    method: 'get',
    params: data
  })
}

// 根据uuid获取对话记录
export function dialogues (data) {
  return service({
    url: '/v1/chat/dialogues',
    method: 'get',
    params: data
  })
}

// 将AI返回的对话转换为word文档
export function chatToWord (data) {
  return service({
    url: '/v1/word/chatToWord',
    method: 'get',
    params: data
  })
}

// 获取对话参数配置
export function chatOptionsByChatType (data) {
  return service({
    url: '/v1/chat/chatOptionsByChatType',
    method: 'get',
    params: data
  })
}

// 登出
export function logout (data) {
  return service({
    url: '/v1/auth/logout',
    method: 'get',
    params: data
  })
}

// 获取用户document列表
export function getDictByParentId (data) {
  return service({
    url: '/v1/dict/getDictByParentId',
    method: 'get',
    params: data
  })
}

// 获取用户document列表
export function readDocContentByFileObj (data) {
  return service({
    url: '/v1/document/readDocContentByFileObj',
    method: 'post',
    data,
    headers:{
      'ContentType': 'application/json;charset=UTF-8',
    }
  })
}