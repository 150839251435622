<template>
  <div class="menu">
    <div class="title" @click="chatTypeHandle({chatType: null})">
      <img class="t_ai" src="./imgs/ai.png" alt="">
      <img class="t_img" src="./imgs/title.png" alt="">
    </div>
    <div class="box">
      <div :class="['row', chatType == item.chatType ? 'select' : '']" @click="chatTypeHandle(item)" v-for="item in dataList" :key="item.chatType" v-show="item.showType != 2">
        <img :src="staticH + item.titleIcon" alt="" srcset="">
        <span>{{ item.title }}</span>
        <span v-if="item.tagIcon">
          <img style="width: 48px;height: 20px;margin-top: 6px;" :src="staticH + item.tagIcon" alt="">
        </span>
      </div>
    </div>
    <hr style="border: 1px solid rgba(232,236,239,1);">
    <div class="box1">
      <div class="row" v-for="item in dataList" :key="item.chatType" v-show="item.showType == 2" @click="wish">
        <img :src="staticH + item.titleIcon" alt="" srcset="">
        <span>{{ item.title }}</span>
      </div>
      <div class="row" @click="goHistory">
        <img src="./imgs/history.png" alt="" srcset="">
        <span>历史对话</span>
      </div>
    </div>
    <div class="dialog">
      <el-dialog
        v-model="dialogVisible"
        width="1000px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        center
      > 
        <template #title>
          <div class="switchTabs">
            <div :class="switchType==1?'ft600':''" style="margin-right:24px;cursor: pointer;" @click="switchTypeCall(1)">
              许愿功能
              <div v-if="switchType==1" class="line"></div>
              <div v-else class="whiteLine"></div>
            </div>
            <div :class="switchType==2?'ft600':''" style="margin-left: 24px;cursor: pointer;" @click="switchTypeCall(2)">
              意见反馈
              <div v-if="switchType==2" class="line"></div>
              <div v-else class="whiteLine"></div>
            </div>
          </div>
        </template>
        <div>
          <div class="dialog-title">
            <img src="../../assets/imgs/happy.png" />
            <div v-if="switchType==1">许愿你想要的功能，呼声高的功能将会优先开放</div>
            <div v-if="switchType==2">反馈内容（必填）</div>
          </div>
          <div v-if="switchType==1">
            <el-input
              v-model="wishContent"
              :rows="10"
              maxlength="1000"
              type="textarea"
              placeholder="许愿您想要的任何其他功能～"
              resize="none"
            />
            <div class="count">{{wishContent.length}}/1000</div>
          </div>
          <div v-if="switchType==2">
            <el-input
              v-model="ideaContent"
              :rows="10"
              maxlength="1000"
              type="textarea"
              placeholder="请输入您对我们的意见或建议，我们会尽快处理。"
              resize="none"
            />
            <div class="count">{{ideaContent.length}}/1000</div>
          </div>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <div class="confirm" @click="confirm">提 交</div>
          </div>
        </template>
      </el-dialog>
    </div>
    <div class="bottom" @click="toRechargeHandle">
      <div class="bot_text">
        <p>AI豆购买&gt;</p>
        <p>购买畅快用</p>
      </div>
      <img src="@/assets/imgs/recharge.png" alt="">
    </div>
  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus';
import { menuList,addFeedback } from '@/api/index';
import { ref, computed } from 'vue';
import { useStore } from "vuex";
const store = useStore();
import location from "@/utils/location";
import { useRoute, useRouter } from 'vue-router';
const route = useRoute()
const router = useRouter()
const staticH = location.static1();

const dataList = ref([]);
const chatType = computed(() => store.state.chatType || sessionStorage.getItem('chatType'));
// isLock
const isLock = computed(() => store.state.isLock);
// 查询菜单数据
const menuListM = () => {
  menuList().then(res => {
    if (res.code == 200) {
      dataList.value = res.data;
      store.commit('changeMenu', res.data)
    } else {
      ElMessage.error(res.msg);
    };
  });
};
menuListM();
// 切换
const chatTypeHandle = (val) => {
  if (isLock.value) return ElMessage.warning('对话进行中，请稍后再试');
  store.commit('changeChatType', val.chatType);
  if (val.chatType == null) {
    store.commit('changeUuid', false);
    router.push({
      path: '/home'
    });
  } else {
    store.commit('changeUuid', true);
    router.push({
      path: '/chatRoom'
    });
  };
};
const toRechargeHandle = () => {
  if (isLock.value) return ElMessage.warning('对话进行中，请稍后再试');
  router.push('/recharge')
};
const goHistory = ()=>{
  if (isLock.value) return ElMessage.warning('对话进行中，请稍后再试');
  router.push('/historyRecord')
}
let wishContent = ref('')
let ideaContent = ref('')
let dialogVisible = ref(false)
let switchType = ref(1)
const wish = ()=>{
  if (isLock.value) return ElMessage.warning('对话进行中，请稍后再试');
  wishContent.value = ''
  ideaContent.value = ''
  switchType.value = 1
  dialogVisible.value=true
}
const switchTypeCall = (type)=>{
  switchType.value=type
}

const confirm = ()=>{
  if(switchType.value==1&&!wishContent.value.trim()) return ElMessage.warning('请输入愿望')
  if(switchType.value==2&&!ideaContent.value.trim()) return ElMessage.warning('请输入建议')
  addFeedback({
    msg: switchType.value==1?wishContent.value:ideaContent.value,
    type: switchType.value==1? 1: 0
  }).then(res=>{
    if(res.code==200){
      ElMessage.success('提交成功')
      dialogVisible.value=false
    }else{
      ElMessage.error(res.message)
    }
  })
}
</script>

<style lang="scss" scoped>
.menu {
  padding: 24px 16px;
  background: #FFFFFF;
  height: 100%;
  box-sizing: border-box;
  width: 240px;
  position: relative;
  .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    .t_ai {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }
    .t_img {
      width: 96px;
      height: 29px;
    }
  }
  .box {
    margin-top: 24px;
    overflow: auto;
    height: calc(100% - 180px);
    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      margin: 16px 0px;
      cursor: pointer;
      padding-left: 20px;
      span {
        font-size: 16px;
        color: #191D20;
        letter-spacing: 0;
        font-weight: 400;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .select {
      background: rgba(54,145,252,0.08);
      border-radius: 8px;
      span {
        color: #3691FC;
      }
    }
    .row:hover {
      background: rgba(54,145,252,0.08);
      border-radius: 8px;
      span {
        color: #3691FC;
      }
    }
  }
  .box::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  .box::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
    display: none;
  }
  .box::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .box::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    height: 6px;
    border-radius: 25px;
    background-clip: padding-box;
    background-color: rgba(0, 0, 0, 0.3);
  }
  .box1 {
    display: flex;
    justify-content: space-around;
    .row {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 40px;
      cursor: pointer;
      span {
        font-size: 14px;
        color: #7E9CB3;
        letter-spacing: 0;
        font-weight: 400;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .row:hover {
      opacity: 0.8;
    }
  }
  .bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    cursor: pointer;
    .bot_text {
      position: absolute;
      left: 45px;
      top:30px;
      p:nth-child(1) {
        font-size: 16px;
        color: #3691FC;
        letter-spacing: 0;
        font-weight: 600;
      }
      p:nth-child(2) {
        font-size: 12px;
        color: #374047;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
    }
  }
}
.dialog{
  ::v-deep .el-dialog{
    border-radius: 24px;
  }
  .switchTabs{
    font-family: PingFangSC-Regular;
    font-size: 22px;
    color: #6E7881;
    letter-spacing: 0;
    font-weight: 400;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .ft600{
      font-family: PingFangSC-Semibold;
      color: #191D20;
      font-weight: 600;
    }
    .line{
      margin: 0 auto;
      margin-top: 8px;
      background-image: linear-gradient(90deg, #3BE1F9 0%, #3363FF 44%, #9377FF 100%);
      border-radius: 3px;
      width: 48px;
      height: 6px;
    }
    .whiteLine{
      margin: 0 auto;
      margin-top: 8px;
      background:#fff;
      border-radius: 3px;
      width: 48px;
      height: 6px;
    }
  }
  .dialog-title{
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #191D20;
    letter-spacing: 0;
    font-weight: 400;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    img{
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  ::v-deep .el-textarea__inner{
    background: #F4F8FD;
    border-radius: 16px;
    padding: 12px 16px;
  }
  .count{
    margin-top: 7px;
    margin-right: 16px;
    text-align: right;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #A6B6C8;
    letter-spacing: 0;
    font-weight: 400;
  }
  ::v-deep .el-dialog__body{
    padding-bottom: 0;
  }
  .dialog-footer{
    display: flex ;
    align-items: center;
    justify-content: center;
  }
  .confirm{
    background-image: linear-gradient(90deg, #3BE1F9 0%, #3363FF 44%, #9377FF 100%);
    border-radius: 8px;
    width: 136px;
    height: 40px;
    box-sizing: border-box;
    // padding: 10px 45px;
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
