import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store";
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '主页'
    },
    component: () => import('@/views/home.vue'),
  },
  {
    path: '/chatRoom',
    name: 'chatRoom',
    meta: {
      title: '聊天室'
    },
    component: () => import('@/views/chatRoom.vue'),
  },
  {
    path: '/historyRecord',
    name: 'historyRecord',
    meta: {
      title: '历史记录'
    },
    component: () => import('@/views/historyRecord.vue'),
  },
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      title: '账户'
    },
    component: () => import('@/views/recharge/index.vue'),
    redirect: '/recharge/index',
    children:[
      {
        path: '/recharge/index',
        name: 'rechargec',
        meta: {
          title: '购买'
        },
        component: () => import('@/views/recharge/recharge.vue'),
      },
      {
        path: '/recharge/history',
        name: 'rechargeh',
        meta: {
          title: '使用明细'
        },
        component: () => import('@/views/recharge/history.vue'),
      }
    ]
  
  },
  {
    path: '/pay',
    name: 'pay',
    meta: {
      title: '购买'
    },
    component: () => import('@/views/pay.vue'),
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 始终滚动到顶部
    return { top: 0 }
  },
})
// 路由守卫--start
router.beforeEach((to, from, next) => {
  next();
});

// 路由守卫--end
export default router
