import { createStore } from 'vuex';
export default createStore({
  state: {
    menu: [],
    chatType: null,
    uuid: null,
    isLock: false
  },
  getters: {
  },
  mutations: {
    changeMenu(state, val) {
      state.menu = val;
    },
    changeChatType(state, val) {
      if (val == null) {
        sessionStorage.removeItem('chatType');
      } else {
        sessionStorage.setItem('chatType', val);
      };
      state.chatType = val;
    },
    changeUuid(state, val) {
      // false 删除 true更改
      let uuid;
      if (!val) {
        sessionStorage.removeItem('uuid');
        uuid = null;
      } else {
        uuid = new Date().getTime() + Math.ceil(Math.random() * 1000)
        sessionStorage.setItem('uuid', uuid);
      }
      state.uuid = uuid;
    },
    changeLock(state, val) {
      state.isLock = val;
    }
  },
  actions: {
  },
  modules: {
  }
})
