import service from '@/utils/request';

// 筛选项
export function getChatTypes(data) {
  return service({
    url: '/v1/chat/chatTypes',
    method: 'get',
    params: data
  })
}
// 获取会话历史，不包含详细对话
export function getHistoryList(data) {
  return service({
    url: '/v1/chat/chats',
    method: 'get',
    params: data
  })
}
// 新增意见或建议
export function addFeedback(data) {
  return service({
    url: '/v1/feedback/add',
    method: 'post',
    data
  })
}
// deleteChatByUuid
export function deleteChatByUuid(data) {
  return service({
    url: '/v1/chat/deleteChatByUuid',
    method: 'delete',
    params: data
  })
}
// 重命名历史记录
export function renameHistory(data) {
  return service({
    url: '/v1/chat/rename',
    method: 'post',
    data
  })
}