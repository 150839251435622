const location = {
  // 接口域名
  domain () {
    let URL = window.location.origin
    // URL = 'http://cas.dev.teacheredu.cn'             // dev环境
    // URL = 'http://cas.cs.teacheredu.cn'              // cs环境
    // URL = 'http://cas.cspub.teacheredu.cn'           // cspub环境
    // URL = 'http://cas.study.teacheredu.cn'           // 教师网外网
    // URL = 'http://cas.history.teacheredu.cn'         // 历史环境
    // URL = 'http://cas.jszgz.teacheredu.cn'           // 教师资格环境
    // URL = 'http://cas.study.yanxiu.jsyxsq.com'       // 中国教育电视台
    // URL = 'http://cas.sxsd.01601.cn'                 // 陕西师大
    // URL = 'http://cas.01601.cn'                      // 洮北（其他公用）
    // URL = 'http://cas.study.bcvet.com.cn'            // 百年树人
    // URL = 'https://cas.ylcs.teacheredu.cn'           // 压力测试
    return URL + '/'
  },
  // 静态资源域名
  static () {
    let URL = this.domain().replace('//cas', '//static')
    if (URL.indexOf('static') == -1) {
      let arr = URL.split('//')
      URL = arr[0] + '//static.' + arr[1];
    }
    return URL
  },
  // 静态资源域名1
  static1 () {
    let URL = this.domain() + 'static'
    return URL
  },
  // 三级页域名
  html () {
    let URL = this.domain().replace('//cas', '//html')
    if (URL.indexOf('html') == -1) {
      let arr = URL.split('//')
      URL = arr[0] + '//html.' + arr[1];
    }
    return URL
  },
  // 文件域名
  files () {
    let URL = this.domain().replace('//cas', '//files')
    if (URL.indexOf('files') == -1) {
      let arr = URL.split('//')
      URL = arr[0] + '//files.' + arr[1];
    }
    return URL
  },
  // 文件域名
  files1 () {
    let URL = this.domain() + 'files'
    return URL
  },
  // 预览域名
  preview () {
    let URL = window.location.href
    if (URL.indexOf('cs.teacheredu') > 0) { 
      URL = 'http://viewfile.cs.teacheredu.cn/onlinePreview/viewFile?url='    // cs环境
    } else if (URL.indexOf('cspub.teacheredu') > 0) { 
      URL = 'http://office.cspub.teacheredu.cn/onlinePreview/viewFile?url='   // cspub环境
    }  else  {
      URL = 'http://office.teacheredu.cn/onlinePreview/viewFile?url='         // 外网
    }
    return URL
  }
}
export default location
