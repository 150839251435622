<template>
  <div class="header">
    <img style="border-radius: 100%;" :src="`${files}/el/uc/face/u${intelligenceInfo.id}.jpg?key=${Math.random()}`" alt="" srcset="">
    <span>{{ intelligenceInfo.nikeName }}</span>
    <div class="quitBtn" @click="logoutM">退出</div>
  </div>
</template>

<script setup scoped>
import { ElMessage } from 'element-plus';
import { logout } from '@/api/index';
import location from "@/utils/location";
const files = location.files1();
const domain = location.domain();
const intelligenceInfo = JSON.parse(sessionStorage.getItem('intelligenceInfo'));

// 退出
const logoutM = () => {
  logout().then(res => {
    if (res.code == 200) {
      window.close();
    } else {
      ElMessage.error(res.messge);
    };
  });
};
</script>

<style lang="scss">
.header {
  height: 60px;
  padding: 0px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  img {
    width: 28px;
    height: 28px;
    margin-right: 8px;
  }
  span {
    font-size: 14px;
    color: #374047;
    letter-spacing: 0;
    font-weight: 400;
    margin-right: 16px;
  }
  .quitBtn {
    background: #d5d8db;
    border-radius: 4px;
    font-size: 14px;
    color: #6E7881;
    letter-spacing: 0;
    font-weight: 400;
    padding: 2px 8px;
    cursor: pointer;
  }
}
</style>
