<template>
  <el-config-provider :locale="locale">
    <div id="box" v-if="isShowPro">
      <div class="left" v-if="!route.path.includes('pay')"><Menu></Menu></div>
      <div class="right">
        <Header  v-if="!route.path.includes('pay')"></Header>
        <router-view />
      </div>
    </div>
  </el-config-provider>
</template>
<script setup>
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
const locale = zhCn;
import { ElMessageBox } from 'element-plus';
import { jumpLogin } from '@/api/index';
import { ref } from 'vue';
import Menu from '@/components/menu/index.vue';
import Header from '@/components/header.vue';
import {useRoute} from 'vue-router';
const route = useRoute();
import location from "@/utils/location";
const domain = location.domain();
const isShowPro = ref(false);
// 获取token
const jumpLoginM = (info) => {
  jumpLogin({info}).then(res => {
    if (res.code == 200) {
      isShowPro.value = true
      sessionStorage.setItem('intelligenceInfo', JSON.stringify(res.data));
    } else {
      ElMessageBox.alert('登录信息出现错误请您前往登录', '警告', {
        confirmButtonText: '确定',
        type: 'warning',
        callback: () => {
          window.close();
        }
      });
    };
  });
};
setTimeout(() => {
  let info = '';
  if (route.query.info) {
    sessionStorage.setItem('info', route.query.info);
    info = route.query.info;
  } else {
    info = sessionStorage.getItem('info');
  };
  jumpLoginM(info);
}, 500);
</script>
<style lang="scss">
* {
  margin: 0px;
}
html,body,#app,#box {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(122deg, #FBF5FB 0%, #DCEBFD 100%);
}
#box {
  display: flex;
  .left {
    width: 240px;
  }
  .right {
    flex: 1;
  }
}
</style>
